/**
 * This file is automatically generated.
 *
 * **Do not** modify this file directly, but instead update
 * the 'write-version.mjs' script.
 */

/**
 * Version type following a subset of the Semantic Versioning specification.
 */
export type Version = {major: number, minor: number, patch: number, rc: number};

/** Version of this API. */
export const APIVersion: Version = {
    major: 1,
    minor: 2,
    patch: 3,
    rc: 0
};
